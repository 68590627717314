import { defineStore, acceptHMRUpdate } from 'pinia';
import { ref, computed } from 'vue';
import { formatPrice, percentageStringToNumber } from '~/utils/formatters';
import handleFrontendError from '../utils/handleFrontendError';
import type { PricingTierWithRules, Product, Terpene } from '~/types/app.types';

export const useProductsStore = defineStore('Products', () => {
	const baseUrl = useRuntimeConfig().public.baseUrl;
	const products = ref<Product[]>([]);
	const pricingTiers = ref<PricingTierWithRules[]>([]);
	const isLoading = computed(() => !products.value.length);

	// User selected store location. This is important for furture stores. For not it's hardcoded
	const storeLocationId = 1934; // Monroe location id

	// Filter refs
	const brandUserSelection = ref('All');
	const categoryUserSelection = ref('All');
	const chapterUserSelection = ref('All');
	const leanUserSelection = ref(['All']);
	const priceTierUserSelection = ref('All');
	const dominateTerpeneUserSelection = ref<Terpene | 'All'>('All');
	const minPriceUserSelection = ref<number | null>(null);
	const maxPriceUserSelection = ref<number | null>(null);
	const minThcUserSelection = ref<number | null>(null);
	const maxThcUserSelection = ref<number | null>(null);
	const searchTermUserSelection = ref('');
	const excludedSearchProporties = [
		'createdAt',
		'dutchieId',
		'id',
		'image',
		'images',
		'metadataId',
		'posId',
		'pricingTierId',
		'primaryCategoryId',
		'quantity',
		'strainId',
	];
	const sortingOptions = ['Select an Option', 'Alphabetically', 'Highest THC'];
	const sortByUserSelection = ref('Select an Option');

	// Pagination refs
	const currentPage = ref(1);
	const nextPage = ref<number | null>(2);
	const previousPage = ref<number | null>(null);
	const totalItems = ref<number | null>(null);
	const itemsPerPage = ref(20);
	const totalPages = ref(1);

	/**
	 * Checks for a valid value
	 * This is used to filter out undesirable value from the filter options
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	function isEligible(value: any) {
		if (value !== false || value !== null || value !== 0 || value !== '') {
			return value;
		}
	}

	const brandOptions = computed<Set<string>>(() => {
		const customOrdering = {
			All: 1,
			Weedys: 2,
		};
		const brands: string[] = [];
		products.value.forEach((product) => {
			if (product.brand?.name) {
				brands.push(product.brand.name);
			}
		});
		brands.unshift('All');

		const brandsSet = new Set(
			brands.sort((a, b) => {
				const priorityA = customOrdering[a];
				const priorityB = customOrdering[b];

				if (priorityA !== undefined && priorityB !== undefined) {
					return priorityA - priorityB;
				} else if (priorityA !== undefined) {
					return -1; // A has priority but B doesn't, so A comes first
				} else if (priorityB !== undefined) {
					return 1; // B has priority but A doesn't, so B comes first
				} else {
					return a.localeCompare(b); // Neither A nor B has a priority, sort them alphabetically
				}
			}),
		);
		return brandsSet;
	});

	const brandOptionsCount = computed(() => {
		if (!products.value || !brandOptions.value) return null;
		const brandCounts = {};
		brandOptions.value.forEach((brand) => {
			if (brand !== 'All') {
				brandCounts[brand] = 0;
			}
		});

		if (filteredProducts.value) {
			filteredProducts.value.forEach((product) => {
				const brandName = product.brand?.name;

				if (brandName && brandOptions.value.has(brandName)) {
					brandCounts[brandName]++;
				}
			});
		}

		return brandCounts;
	});

	/** A list of all categories for in-stock products
	 * Dec 2023 update - Chapters are stored as categories but a request was made to separate
	 * them from the other categories in the filter.
	 */
	const categoryOptions = computed<Set<string>>(() => {
		const customOrdering = {
			All: 1,
			'Bundle Pack': 2,
			Flower: 3,
			Preroll: 4,
			Concentrate: 5,
			Merch: 5,
		};

		let categories: string[] = [];
		products.value.forEach((product) => {
			product.productMetadata?.productCategories?.forEach((category) => {
				if (!category.name.startsWith('Chapter')) {
					categories.push(category.name);
				}
			});
		});

		categories = categories.filter(isEligible);
		categories.unshift('All');

		const categorySet = new Set(
			categories.sort((a, b) => {
				const priorityA = customOrdering[a];
				const priorityB = customOrdering[b];

				if (priorityA !== undefined && priorityB !== undefined) {
					return priorityA - priorityB;
				} else if (priorityA !== undefined) {
					return -1; // A has priority but B doesn't, so A comes first
				} else if (priorityB !== undefined) {
					return 1; // B has priority but A doesn't, so B comes first
				} else {
					return a.localeCompare(b); // Neither A nor B has a priority, sort them alphabetically
				}
			}),
		);
		return categorySet;
	});

	const categoryOptionsCount = computed(() => {
		if (!products.value || !categoryOptions.value) return null;
		const categoriesCount = {};
		categoryOptions.value.forEach((category) => {
			if (category !== 'All') {
				categoriesCount[category] = 0;
			}
		});

		if (filteredProducts.value) {
			filteredProducts.value.forEach((product) => {
				const categories = product.productMetadata?.productCategories;

				if (categories) {
					categories.forEach((category) => {
						if (categoryOptions.value.has(category.name)) {
							categoriesCount[category.name]++;
						}
					});
				}
			});
		}

		return categoriesCount;
	});

	const chapterOptions = computed<Set<string>>(() => {
		let chapters: string[] = [];
		products.value.forEach((product) => {
			product.productMetadata?.productCategories?.forEach((category) => {
				if (category.name.startsWith('Chapter')) {
					chapters.push(category.name);
				}
			});
		});

		chapters = chapters.filter(isEligible);
		chapters.unshift('All');

		const categorySet = new Set(chapters.sort());
		return categorySet;
	});

	const chapterOptionsCount = computed(() => {
		if (!products.value || !chapterOptions.value) return null;
		const chaptersCount = {};
		chapterOptions.value.forEach((category) => {
			if (category !== 'All') {
				chaptersCount[category] = 0;
			}
		});

		if (filteredProducts.value) {
			filteredProducts.value.forEach((product) => {
				const categories = product.productMetadata?.productCategories;

				if (categories) {
					categories.forEach((category) => {
						if (chapterOptions.value.has(category.name)) {
							chaptersCount[category.name]++;
						}
					});
				}
			});
		}

		return chaptersCount;
	});

	/** A list of all leans for in-stock product */
	const leanOptions = computed<Set<string>>(() => {
		const customOrdering = {
			All: 1,
			Indica: 2,
			'Indica-Hybrid': 3,
			Hybrid: 4,
			'Sativa-Hybrid': 5,
			Sativa: 6,
		};

		let leans: string[] = [];
		products.value.forEach((product) => {
			if (product.strains?.lean) leans.push(product.strains.lean);
		});

		leans = leans.filter(isEligible);
		leans.unshift('All');

		const leanSet = new Set(
			leans.sort((a, b) => {
				const priorityA = customOrdering[a];
				const priorityB = customOrdering[b];

				if (priorityA !== undefined && priorityB !== undefined) {
					return priorityA - priorityB;
				} else if (priorityA !== undefined) {
					return -1; // A has priority but B doesn't, so A comes first
				} else if (priorityB !== undefined) {
					return 1; // B has priority but A doesn't, so B comes first
				} else {
					return a.localeCompare(b); // Neither A nor B has a priority, sort them alphabetically
				}
			}),
		);
		return leanSet;
	});

	const leanOptionsCount = computed(() => {
		if (!products.value || !leanOptions.value) return null;
		const leansCount = {};
		leanOptions.value.forEach((lean) => {
			if (lean !== 'All') {
				leansCount[lean] = 0;
			}
		});

		if (filteredProducts.value) {
			filteredProducts.value.forEach((product) => {
				const lean = product.strains?.lean;
				if (lean && leanOptions.value.has(lean)) {
					leansCount[lean]++;
				}
			});
		}

		return leansCount;
	});

	/** A list of all price tiers for in-stock products */
	const priceTierOptions = computed<Set<string>>(() => {
		let tiers: string[] = [];
		products.value.forEach((product) => {
			if (product.pricingTiers?.name) tiers.push(product.pricingTiers.name);
		});

		tiers = tiers.filter(isEligible);
		tiers.unshift('All');

		const priceTierSet = new Set(tiers.sort());
		return priceTierSet;
	});

	const priceTierOptionsCount = computed(() => {
		if (!products.value || !priceTierOptions.value) return null;
		const priceTierCount = {};
		priceTierOptions.value.forEach((tier) => {
			if (tier !== 'All') {
				priceTierCount[tier] = 0;
			}
		});

		if (filteredProducts.value) {
			filteredProducts.value.forEach((product) => {
				const tier = product.pricingTiers?.name;
				if (tier && priceTierOptions.value.has(tier)) {
					priceTierCount[tier]++;
				}
			});
		}

		return priceTierCount;
	});

	const dominateTerpeneOptions = computed<Set<string>>(() => {
		let terpenes: string[] = [];
		products.value.forEach((product) => {
			if (product.strains?.dominateTerpene) terpenes.push(product.strains.dominateTerpene);
		});

		terpenes = terpenes.filter(isEligible);
		terpenes.unshift('All');

		const dominateTerpenesSet = new Set(terpenes.sort());
		return dominateTerpenesSet;
	});

	const dominateTerpeneOptionsCount = computed(() => {
		if (!products.value || !dominateTerpeneOptions.value) return null;
		const dominateTerpenesCount = {};
		dominateTerpeneOptions.value.forEach((terpene) => {
			if (terpene !== 'All') {
				dominateTerpenesCount[terpene] = 0;
			}
		});

		if (filteredProducts.value) {
			filteredProducts.value.forEach((product) => {
				const terpene = product.strains?.dominateTerpene;
				if (terpene && dominateTerpeneOptions.value.has(terpene)) {
					dominateTerpenesCount[terpene]++;
				}
			});
		}

		return dominateTerpenesCount;
	});

	/** Calculates the lowest and highest prices in the products list */
	const priceRangeOptions = computed<{ lowestPrice: number; highestPrice: number }>(() => {
		const { lowestPrice, highestPrice } = products.value.reduce(
			(acc, product) => {
				let productPrice: number | undefined;

				if (product.price) {
					productPrice = product.price;
				} else if (product.pricingTierId) {
					const tier = pricingTiers.value.find(
						(tier) => tier.id === product.pricingTierId,
					);

					if (tier) {
						productPrice = formatPrice(
							tier.pricingTierRules[0].startWeightGrams,
							tier.pricingTierRules[0].pricePerGram,
						);
					}
				}

				if (productPrice) {
					acc.lowestPrice = Math.min(acc.lowestPrice, productPrice);
					acc.highestPrice = Math.max(acc.highestPrice, productPrice);
				}

				return acc;
			},
			{ lowestPrice: 100_000, highestPrice: 0 },
		);

		minPriceUserSelection.value = lowestPrice;
		maxPriceUserSelection.value = highestPrice;
		return { lowestPrice, highestPrice };
	});

	/** Calculates the lowest and highest thc content in the products list */
	const thcOptions = computed<{ lowestThc: number; highestThc: number }>(() => {
		const { lowestThc, highestThc } = products.value.reduce(
			(acc, product) => {
				const thcContent = product.productMetadata?.potencyThc
					? percentageStringToNumber(product.productMetadata.potencyThc)
					: null;

				if (thcContent !== null) {
					acc.lowestThc = Math.min(acc.lowestThc, thcContent);
					acc.highestThc = Math.max(acc.highestThc, thcContent);
				}

				return acc;
			},
			{ lowestThc: 100, highestThc: 0 },
		);

		minThcUserSelection.value = lowestThc;
		maxThcUserSelection.value = highestThc;
		return { lowestThc, highestThc };
	});

	/**
	 * Paginates the list of filtered products
	 * This function is used to create a paginated shop based on the current page and products per page.
	 * It also updates previousPage, nextPage, totalItems, and totalPages variables.
	 *
	 * @param filteredProducts - The products to be paginated.
	 * @returns - The paginated products for the current page.
	 */
	function paginate(filteredProducts: Product[]) {
		const offset = itemsPerPage.value * (currentPage.value - 1);
		const pages = Math.ceil(filteredProducts.length / itemsPerPage.value);
		const paginatedProducts = filteredProducts.slice(
			offset,
			itemsPerPage.value * currentPage.value,
		);

		previousPage.value = currentPage.value - 1 ? currentPage.value - 1 : null;
		nextPage.value = pages > currentPage.value ? currentPage.value + 1 : null;
		totalItems.value = filteredProducts.length;
		totalPages.value = pages;

		return paginatedProducts;
	}

	/** A filtered list of products */
	const filteredProducts = computed(() => {
		let filteredProducts = [...products.value];

		// Category filter
		if (
			categoryUserSelection.value !== 'All Categories' &&
			categoryUserSelection.value !== 'All'
		) {
			filteredProducts = filteredProducts.filter((product) => {
				return product.productMetadata?.productCategories?.some(
					(el) => el.name === categoryUserSelection.value,
				);
			});
		}

		// Chapter filter
		if (chapterUserSelection.value !== 'All Chapters' && chapterUserSelection.value !== 'All') {
			filteredProducts = filteredProducts.filter((product) => {
				return product.productMetadata?.productCategories?.some(
					(el) => el.name === chapterUserSelection.value,
				);
			});
		}

		// Dominate terpene filter
		if (dominateTerpeneUserSelection.value !== 'All') {
			filteredProducts = filteredProducts.filter(
				(product) =>
					product.strains?.dominateTerpene === dominateTerpeneUserSelection.value,
			);
		}

		// Lean filters
		if (!leanUserSelection.value.includes('All')) {
			filteredProducts = filteredProducts.filter((product) => {
				if (product.strains?.lean) {
					return leanUserSelection.value.includes(product.strains.lean);
				}
			});
		}

		// Price tier filter
		if (priceTierUserSelection.value !== 'All') {
			filteredProducts = filteredProducts.filter((product) => {
				return product.pricingTiers?.name === priceTierUserSelection.value;
			});
		}

		// Brand filter
		if (brandUserSelection.value !== 'All') {
			filteredProducts = filteredProducts.filter((product) => {
				return product.brand?.name === brandUserSelection.value;
			});
		}

		// Price range filter
		filteredProducts = filteredProducts.filter((product) => {
			if (maxPriceUserSelection.value && minPriceUserSelection.value) {
				if (product.price) {
					return (
						product.price <= maxPriceUserSelection.value &&
						product.price >= minPriceUserSelection.value
					);
				} else if (product.pricingTierId) {
					const tier = pricingTiers.value.find((el) => el.id === product.pricingTierId);

					if (tier) {
						return (
							formatPrice(
								tier.pricingTierRules[0].startWeightGrams,
								tier.pricingTierRules[0].pricePerGram,
							) <= maxPriceUserSelection.value &&
							formatPrice(
								tier.pricingTierRules[0].startWeightGrams,
								tier.pricingTierRules[0].pricePerGram,
							) >= minPriceUserSelection.value
						);
					}
				}
			}
		});

		// THC filter
		if (
			maxThcUserSelection.value &&
			minThcUserSelection.value &&
			(maxThcUserSelection.value < thcOptions.value.highestThc ||
				minThcUserSelection.value > thcOptions.value.lowestThc)
		) {
			filteredProducts = filteredProducts.filter((product) => {
				if (product.productMetadata?.potencyThc) {
					const thcContent = percentageStringToNumber(product.productMetadata.potencyThc);
					if (maxThcUserSelection.value && minThcUserSelection.value) {
						return (
							thcContent >= minThcUserSelection.value &&
							thcContent <= maxThcUserSelection.value
						);
					}
				}
			});
		}

		// Search bar
		if (searchTermUserSelection.value !== '') {
			filteredProducts = filteredProducts.filter((obj) => {
				for (const key in obj) {
					if (
						!excludedSearchProporties.includes(key) &&
						obj[key] !== null &&
						obj[key] !== undefined &&
						obj[key] &&
						typeof obj[key] !== 'boolean' &&
						obj[key]
							.toString()
							.toLowerCase()
							.includes(searchTermUserSelection.value.toLowerCase())
					) {
						return true;
					}
				}
				return false;
			});
		}

		// Sort by
		if (sortByUserSelection.value === 'Highest THC') {
			filteredProducts = filteredProducts.sort((a, b) => {
				const productA = a.productMetadata?.potencyThc
					? percentageStringToNumber(a.productMetadata.potencyThc)
					: 0;
				const productB = b.productMetadata?.potencyThc
					? percentageStringToNumber(b.productMetadata.potencyThc)
					: 0;
				return productB - productA;
			});
		}

		if (sortByUserSelection.value === 'Alphabetically') {
			filteredProducts = filteredProducts.sort((a, b) => a.name.localeCompare(b.name));
		}

		return filteredProducts;
	});

	/**
	 * Watch for user filter selection and reset page to 1.
	 * This is to avoid empty pages when the user is on a page other than #1 and the returned
	 * filteredProducts contains fewer elements than the specified itemsPerPage.
	 */
	watch(
		[
			categoryUserSelection,
			leanUserSelection,
			priceTierUserSelection,
			maxPriceUserSelection,
			minPriceUserSelection,
			maxThcUserSelection,
			minThcUserSelection,
			searchTermUserSelection,
			sortByUserSelection,
		],
		() => {
			// If any filter changes, reset to the first page
			currentPage.value = 1;
		},
	);

	/** Paginate filtered products */
	const paginatedProducts = computed(() => {
		return paginate(filteredProducts.value);
	});

	async function fetchPriceTiers() {
		try {
			await $fetch<{ pricingTiers: PricingTierWithRules[] }>(
				`${baseUrl}/api/products/pricing-tiers`,
				{ cache: 'no-cache' },
			).then((response) => (pricingTiers.value = response.pricingTiers));
		} catch (error) {
			handleFrontendError(error, false);
		}
	}

	/**
	 * Fetch in-stock products from a specified store location and populate the products ref with the fetched data.
	 * @param storeLocationId - The ID of the store location to filter products by.
	 */
	async function fetchProducts() {
		try {
			await $fetch<{ products: Product[] }>(
				`${baseUrl}/api/products/location/${storeLocationId}`,
				{ cache: 'no-cache' },
			).then((response) => (products.value = response.products));
		} catch (error) {
			handleFrontendError(error, false);
		}
	}

	/** Reset all user selected filtering options */
	function resetFilterOptions() {
		brandUserSelection.value = 'All';
		categoryUserSelection.value = 'All';
		chapterUserSelection.value = 'All';
		dominateTerpeneUserSelection.value = 'All';
		leanUserSelection.value = ['All'];
		priceTierUserSelection.value = 'All';
		minPriceUserSelection.value = priceRangeOptions.value.lowestPrice;
		maxPriceUserSelection.value = priceRangeOptions.value.highestPrice;
		minThcUserSelection.value = thcOptions.value.lowestThc;
		maxThcUserSelection.value = thcOptions.value.highestThc;
		searchTermUserSelection.value = '';
		sortByUserSelection.value = 'Select an Option';
	}

	return {
		brandOptions,
		brandOptionsCount,
		brandUserSelection,
		categoryUserSelection,
		categoryOptions,
		categoryOptionsCount,
		chapterOptions,
		chapterOptionsCount,
		chapterUserSelection,
		currentPage,
		dominateTerpeneOptions,
		dominateTerpeneOptionsCount,
		dominateTerpeneUserSelection,
		excludedSearchProporties,
		fetchPriceTiers,
		fetchProducts,
		filteredProducts,
		itemsPerPage,
		leanUserSelection,
		leanOptions,
		leanOptionsCount,
		isLoading,
		minPriceUserSelection,
		maxPriceUserSelection,
		maxThcUserSelection,
		minThcUserSelection,
		nextPage,
		paginatedProducts,
		previousPage,
		priceRangeOptions,
		priceTierUserSelection,
		pricingTiers,
		priceTierOptions,
		priceTierOptionsCount,
		products,
		resetFilterOptions,
		searchTermUserSelection,
		sortByUserSelection,
		sortingOptions,
		storeLocationId,
		thcOptions,
		totalItems,
		totalPages,
	};
});

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useProductsStore, import.meta.hot));
}
